.beefup {
  margin: 1em 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.beefup:hover {
  border-color: #ccc;
}
.beefup:first-child {
  margin-top: 0;
}
.beefup__head,
.beefup__body {
  padding: 10px 20px;
}
.beefup__head {
  cursor: pointer;
  position: relative;
  margin: 0;
  padding-right: 50px;
}
button.beefup__head,
.beefup__head > button {
  cursor: pointer;
  display: block;
  width: 100%;
  background: none;
  border: 0;
  font: inherit;
  text-align: inherit;
}
button.beefup__head[aria-disabled],
.beefup__head > button[aria-disabled] {
  cursor: default;
  outline: 0;
}

.beefup__head > button {
  position: relative;
  z-index: 1;
  margin: -10px -50px -10px -20px;
  padding: inherit;
  width: calc(100% + 70px);
  background: inherit;
  border: inherit;
}
.beefup__head::after {
  transition: transform 0.2s ease-out;
  content: "";
  transform: translate(0, -50%) rotate(45deg);
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 0;
  margin-top: -2px;
  padding: 3px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  color: #ccc;
}
.beefup.is-open > .beefup__head::after {
  transform: translate(0, -50%) rotate(225deg);
  margin-top: 2px;
}
