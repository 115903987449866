/*!
 * fullPage 4.0.6
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2021 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
.fp-enabled body,
html.fp-enabled {
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  display: block;
}
.fp-slide {
  float: left;
}
.fp-slide,
.fp-slidesContainer {
  height: 100%;
  display: block;
}
.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.fp-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.fp-slidesContainer {
  float: left;
  position: relative;
}
.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.fp-prev {
  left: 15px;
}
.fp-next {
  right: 15px;
}
.fp-arrow {
  width: 0;
  height: 0;
  border-style: solid;
}
.fp-arrow.fp-prev {
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent;
}
.fp-arrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff;
}
.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}
#fp-nav {
  position: fixed;
  z-index: 100;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translate3d(0, -50%, 0);
}
#fp-nav.fp-right {
  right: 17px;
}
#fp-nav.fp-left {
  left: 17px;
}
.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
}
.fp-slidesNav.fp-bottom {
  bottom: 17px;
}
.fp-slidesNav.fp-top {
  top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}
.fp-slidesNav ul li {
  display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}
#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}
#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}
#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}
.fp-auto-height .fp-slide,
.fp-auto-height.fp-section {
  height: auto !important;
}
.fp-scrollable .fp-section,
.fp-scrollable .fp-slide {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.fp-overflow {
  justify-content: flex-start;
}
.fp-overflow {
  overflow-y: scroll;
  height: 100%;
  outline: 0;
}
.fp-overflow.fp-table {
  display: block;
}
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive.fp-section {
  height: auto !important;
}
.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.fp-snaps {
  overflow-y: auto !important;
  height: 100% !important;
}
.fp-snaps body {
  height: 100% !important;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
}
.fp-snaps .fp-section {
  scroll-snap-align: start;
}
.fp-snaps .fullpage-wrapper {
  height: auto !important;
}
.fp-disable-snap body {
  scroll-behavior: unset !important;
  scroll-snap-type: none !important;
}
.fp-scroll-mac .fp-overflow::-webkit-scrollbar {
  background-color: transparent;
  width: 9px;
}
.fp-scroll-mac .fp-overflow::-webkit-scrollbar-track {
  background-color: transparent;
}
.fp-scroll-mac .fp-overflow::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  border: 4px solid transparent;
}
.fp-warning,
.fp-watermark {
  z-index: 9999999;
  position: absolute;
  bottom: 0;
}
.fp-warning,
.fp-watermark a {
  text-decoration: none;
  color: #000;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 8px;
  font-size: 14px;
  font-family: arial;
  color: #000;
  display: inline-block;
  border-radius: 3px;
  margin: 12px;
}
/*# sourceMappingURL=fullpage.min.css.map */
